export const commonEnv = {
  paginationConfigs: {
    pageSizeOptions: [5, 10, 25, 100, 250, 500],
    pageSize: 10,
  },
  FILTERS_MESSAGES_DEFAULT_DATES: {
    startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
    endDate: new Date(),
  },
  identificationTypes: {
    PROPRIETOR: {
      IDENTITY_CARD: 'National Identity Number',
      PASSPORT: 'Passport Number',
      // DRIVERS_LICENSE: 'Drivers License',
    },
    PROPERTY_MANAGER: {
      INCORPORATION_CERTIFICATE: 'Incorporation Certificate',
    },
  },
  regex: {
    email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
  },
  dialogConfigs: {
    enterAnimationDuration: 200,
    exitAnimationDuration: 200,
    disableClose: true,
  },
  LOCAL_STORAGE_KEYS: {
    sessionID: 'les_ssnnsnj_ddmekdj',
  },
  filePreviewTypes: [
    'image',
    'pdf',
    'csv',
    'sheet',
    'doc',
    'video',
    'audio',
    'other',
  ],
  fileTypes: {
    image: ['image/gif', 'image/jpeg', 'image/png'],
    pdf: ['application/pdf'],
    doc: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    sheet: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ],
    csv: ['text/csv'],
    video: ['video/mp4', 'video/x-ms-wmv'],
    audio: ['audio/mp3', 'audio/wav', 'audio/mpeg'],
  },
};
