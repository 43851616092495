import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private toastr: ToastrService) {}

  success(Message: string, Title = 'Success', Timeout = 5000): void {
    this.toastr.success(Message, Title, {
      timeOut: Timeout,
    });
  }

  warning(Message: string, Title = 'Oops', Timeout = 5000): void {
    this.toastr.warning(Message, Title, {
      timeOut: Timeout,
    });
  }

  info(Message: string, Title = 'Note', Timeout = 5000): void {
    this.toastr.info(Message, Title, {
      timeOut: Timeout,
    });
  }

  error(Message: any, Title = 'Error !', Timeout = 5000): void {
    this.toastr.clear(); // Avoid showing more than one error toasts

    if (Message?.status === 0 || Message?.status === 401) return;

    // Message =
    //   Message?.status === 0 ? environment.API_ERRORS.httpError : Message;
    // Message = Message?.status === 401 ? API_ERRORS['401Error'] : Message;
    // Message = Message?.status === 503 ? API_ERRORS['503Error'] : Message;

    this.toastr.error(Message, Title, {
      timeOut: Timeout,
    });
  }
}
